import axios from "axios"

export const updateSettings = async (tierVal: string | undefined, payout: string | undefined, email: string | undefined, tiers: number[]) => {
    const result = await axios.post("/api/settings", {
        tierVal: tierVal,
        payout: payout,
        email: email,
        tiers: tiers,
    })
    return result.data
}

export const getSettings = async () => {
    const result = await axios.get("/api/settings")
    return result.data
}