import { Alert, Box, Button, Grow, Paper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import LoginIcon from "@mui/icons-material/Login"
import { Stack } from "@mui/system"
import { useUser } from "../Context/User_context"
import { useNavigate } from "react-router-dom"
import ResetPasswordPanel from "../Components/ResetPasswordPanel"

const Login = () => {

    const { login } = useUser()
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [alert, setAlert] = useState<string | undefined>()
    const [resetPanel, setResetPanel] = useState(false)

    const handleLogin = async () => {
        const result = await login(email, password)
        if (result.type === "error") {
            setAlert(result.msg)
        } else {
            navigate("/")
        }
    }

    return (
        <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <Grow in={Boolean(alert)}>
                <Alert severity="error" sx={{ marginTop: 6, width: "230px" }}>{alert}</Alert>
            </Grow>
            <Paper sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, paddingTop: 2, paddingRight: 2, paddingBottom: 4, paddingLeft: 2, marginTop: 2 }}>
                <LoginIcon sx={{ fontSize: "4.5rem" }} />
                <Typography variant="h4">
                    Login.
                </Typography>
                <TextField
                label="Email"
                size="small"
                value={email}
                onChange={(e) => {setEmail(e.target.value)}}
                />
                <TextField
                label="Password"
                size="small"
                type="password"
                value={password}
                onChange={(e) => {setPassword(e.target.value)}}
                />
                <Button onClick={handleLogin} variant="contained">
                    Login
                </Button>
            </Paper>
            <Box sx={{ display: "flex", width: "262px", justifyContent: "flex-end" }}>
                <Button onClick={() => {setResetPanel(true)}}>
                    reset password
                </Button>
            </Box>
            <ResetPasswordPanel
            open={resetPanel}
            onClose={() => {setResetPanel(false)}}
            />
        </Stack>
    )
}

export default Login