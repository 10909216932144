import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import CancelIcon from "@mui/icons-material/Cancel"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import EditIcon from "@mui/icons-material/Edit"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Button from "@mui/material/Button"
import { TextField } from "@mui/material"
import { editUser } from "../Api/User_api"
import { IUser, useUser } from "../Context/User_context"

interface IUserProps {
    user: IUser
    open: boolean,
    onClose: () => void,
    onEdit: (user: any) => void,
}

const SettingsPanel = (props: IUserProps) => {

    const { setNotification } = useUser()

    const { email, fname, lname } = props.user

    const [emailVal, setEmailVal] = useState(email)
    const [fnameVal, setFnameVal] = useState(fname)
    const [lnameVal, setLnameVal] = useState(lname)
    const [passwordVal, setPasswordVal] = useState<string | undefined>()

    const handleEditUser = async () => {
        const result = await editUser(fnameVal, lnameVal, passwordVal, emailVal)
        if (result.type === "error") {
            setNotification({ type: result.type, msg: result.msg })
        } else {
            setNotification({ type: result.type, msg: result.msg })
            props.onEdit({...props.user, email: emailVal, fname: fnameVal, lname: lnameVal })
        }
    }

    useEffect(() => {
        setEmailVal(email)
        setFnameVal(fname)
        setLnameVal(lname)
    }, [email, fname, lname])

    return (
        <Dialog
        onClose={props.onClose}
        open={props.open}
        >
            <DialogTitle sx={{ textAlign: "center", display: "flex", flexDirection: "column", gap: 1 }}>
                <Typography variant="h6" sx={{ paddingBottom: 1 }}>
                    {email}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography>
                        Email:
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                    size="small"
                    value={emailVal}
                    onChange={(e) => {setEmailVal(e.target.value)}}
                    />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography>
                        First name:
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                    size="small"
                    value={fnameVal}
                    onChange={(e) => {setFnameVal(e.target.value)}}
                    />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography>
                        Last name:
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <TextField
                    size="small"
                    value={lnameVal}
                    onChange={(e) => {setLnameVal(e.target.value)}}
                    />
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography>
                        password:
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    {passwordVal === undefined ?
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>
                            •••••••
                        </Typography>
                        <IconButton
                        onClick={() => {setPasswordVal("")}}
                        >
                            <EditIcon />
                        </IconButton>
                    </Box>
                    :
                    <Box sx={{ display: "flex", width: "230px" }}>
                        <TextField
                        size="small"
                        value={passwordVal}
                        onChange={(e) => {setPasswordVal(e.target.value)}}
                        />
                        <IconButton
                        onClick={() => {setPasswordVal(undefined)}}
                        >
                            <CancelIcon />
                        </IconButton>
                    </Box>
                    }
                </Box>
            </DialogTitle>
            <Stack
            direction="row"
            gap={2}
            sx={{ justifyContent: "center", paddingTop: "16px", paddingBottom: "56px" }}
            >
                <Button onClick={handleEditUser} size="large" variant="contained">Update</Button>
                <Button onClick={props.onClose} size="large" variant="outlined">Cancel</Button>
            </Stack>
        </Dialog>
    )
}

export default SettingsPanel