import { Box, Divider, Paper, Typography } from "@mui/material"
import { useUser } from "../Context/User_context"

const Toc = () => {

    const { user } = useUser()

    return (
        <Box sx={{ display: "flex", flexDirection: "column", padding: 3, gap: 2 }}>
            <Typography variant="h4">
                Notice
            </Typography>
            <Divider />
            <Typography variant="h5">
                Tiers
            </Typography>
            <Typography>
                Each tier compromises of ${user?.tierAmt} worth of revenue that is required to progress to the next tier, 
                each tier starting from tier 1 will reward ${user?.payoutAmt} per tier.
            </Typography>
            <Typography variant="h5">
                Progress
            </Typography>
            <Typography>
                The circle holds several bits of information regarding your progress through your current tier, first value
                shows you how much revenue you have earned for your current tier and the second value shows you how much
                revenue is required to progress to the next tier
                <br />
                <br />
                Total revenue below your name and tier shows how much revenue you have accumulated overall for your previous and current tier.
            </Typography>
            <Typography variant="h5">
                Requesting cashout
            </Typography>
            <Typography>
                Requesting cash out will result in sending a non reverse-able request to Nomad Electrical 
                Services requesting the payout amount that is available on your account
                <br />
                <br />
                Requests are only requests and will not deposite a payout immediately as this step is a manual proccess.
            </Typography>
        </Box>
    )
}

export default Toc