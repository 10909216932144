import { Box, Button, CircularProgress, Dialog, DialogTitle, Divider, IconButton, InputBase, Paper, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate, useParams } from "react-router-dom"
import { DataGrid } from "@mui/x-data-grid"
import PriceChangeIcon from "@mui/icons-material/PriceChange"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet"
import PaidIcon from "@mui/icons-material/Paid"
import CardMembershipIcon from "@mui/icons-material/CardMembership"
import { NumericFormat } from "react-number-format"
import ProgressAvatar from "../Components/ProgressAvatar"
import { useEffect, useState } from "react"
import { commissionUser, deleteCommission, getUser } from "../Api/User_api"
import { IUser, useUser } from "../Context/User_context"
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"

interface IRow {
    id: string,
    amount: string,
    message: string,
    createdAt: string,
    delete: {
        deletable: boolean,
    },
    status: {
        val: number,
    },
}

const User = () => {

    const { setNotification } = useUser()
    const navigate = useNavigate()
    const { userid } = useParams()

    const [confirmPanel, setConfirmPanel] = useState(false)
    const [selectedRow, setSelectedRow] = useState<IRow>()

    const [commissionAmt, setCommissionAmt] = useState<string>("")
    const [commissionMsg, setCommissionMsg] = useState<string>("")
    const [rows, setRows] = useState<IRow[]>([])
    const [userData, setUserData] = useState<any>()

    const handleAddCommission = async () => {
        if (userid) {
            const result = await commissionUser(parseFloat(userid), parseFloat(commissionAmt.replace(",", "")), commissionMsg)
            if (result.type === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setNotification({ type: result.type, msg: result.msg })
                setRows([...rows, { status: { val: 0 }, id: result.data, amount: commissionAmt.replace(",", ""), message: commissionMsg, createdAt: new Date().toLocaleString(), delete: { deletable: true } }])
                setCommissionAmt("")
                setCommissionMsg("")
                setUserData({ ...userData, accumulated: userData.accumulated + parseFloat(commissionAmt.replace(",", "")), commission: userData.commission + parseFloat(commissionAmt.replace(",", ""))})
            }
        }
    }

    const handleDeleteCommission = async () => {
        if (userid && selectedRow?.id) {
            const result = await deleteCommission(parseFloat(selectedRow.id))
            if (result.type === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setRows(rows.filter(row => row.id !== selectedRow.id))
                setNotification({ type: result.type, msg: result.msg })
                setConfirmPanel(false)
                setUserData({ ...userData, accumulated: userData.accumulated - parseFloat(selectedRow.amount), commission: userData.commission - parseFloat(selectedRow.amount)})
            }
        }
    }

    useEffect(() => {
        if (userid) {
            getUser(parseFloat(userid)).then(result => {
                if (result.type !== "error") {
                    const { commissionLogs, ...other } = result
                    setRows(commissionLogs.map((log: any) => (
                        {...log, delete: { deletable: log.status === 0 ? true : false }, createdAt: new Date(log.createdAt).toLocaleString()}
                    )))
                    setUserData(other)
                } else {
                    setNotification({ type: result.type, msg: result.msg })
                    navigate("/manage")
                }
            })
        }
    },[])

    const columns = [
        {
            field: "amount",
            headerName: "amount",
            flex: 0.25,
        },
        {
            field: "message",
            headerName: "message",
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "Date",
            flex: 0.5,
        },
        {
            field: "delete",
            renderHeader: () => null,
            renderCell: (params: any) => (
                <Stack direction="row" gap={1} sx={{ width: "100%", alignItems: "center" }}>
                    {params.value.deletable && 
                        <IconButton onClick={() => {setConfirmPanel(true)}}>
                            <DeleteForeverIcon />
                        </IconButton>
                    }
                </Stack>
            ),
            width: 1,
            hideable: true,
        }
    ]

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "16px" }}>
            {userData &&
                <Stack gap={6} sx={{ paddingTop: 3 }}>
                    <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
                        <IconButton onClick={() => {navigate("/manage")}}>
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant="h3" sx={{ fontSize: { xs: "1.3rem", md: "3rem" } }}>
                            {userData?.email}
                        </Typography>
                    </Stack>
                    <Box sx={{ display: "flex", gap: 6, flexDirection: { xs: "column", md: "row" } }}>
                        <ProgressAvatar userData={userData} />
                        <Stack sx={{ flexGrow: 1, gap: 3 }}>
                            <Box sx={{ display: "flex", justifyContent: "space-between", paddingBottom: 3, gap: 3, flexDirection: { xs: "column", md: "row" } }}>
                                <Stack sx={{ alignItems: "center" }}>
                                    <Typography variant="h5">
                                        ${userData?.accumulated.toFixed(2)}
                                    </Typography>
                                    <Box sx={{ display: "flex" }}>
                                        <AccountBalanceWalletIcon />
                                        <Typography>Accumulated Revenue</Typography>
                                    </Box>
                                </Stack>
                                <Stack sx={{ alignItems: "center" }}>
                                    <Typography variant="h5">
                                        ${userData?.commission.toFixed(2)}
                                    </Typography>
                                    <Box sx={{ display: "flex" }}>
                                        <PointOfSaleIcon />
                                        <Typography>Current Revenue</Typography>
                                    </Box>
                                </Stack>
                                <Stack sx={{ alignItems: "center" }}>
                                    <Typography variant="h5">
                                        ${(userData?.accumulated % userData?.tierAmt).toFixed(2)} / ${userData?.tierAmt}
                                    </Typography>
                                    <Box sx={{ display: "flex" }}>
                                        <CardMembershipIcon />
                                        <Typography>Current Tier</Typography>
                                    </Box>
                                </Stack>
                                <Stack sx={{ alignItems: "center" }}>
                                    <Typography variant="h5">
                                        ${userData?.cashed_out}
                                    </Typography>
                                    <Box sx={{ display: "flex" }}>
                                        <PaidIcon />
                                        <Typography>Cashed Out</Typography>
                                    </Box>
                                </Stack>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1, flexDirection: { xs: "column", md: "row" } }}>
                                <Typography variant="h5">
                                    Revenue history:
                                </Typography>
                                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: { xs: "98%", md: 400 } }}>
                                    <Typography sx={{ color: "text.secondary", paddingBottom: "4px", paddingRight: "2px" }}>
                                        $
                                    </Typography>
                                    <NumericFormat
                                    decimalScale={2}
                                    size="small"
                                    customInput={InputBase}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    required
                                    placeholder="00.00"
                                    value={commissionAmt}
                                    onChange={(e) => {setCommissionAmt(e.target.value)}}
                                    sx={{ width: "78px" }}
                                    />
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <InputBase
                                    placeholder="Message"
                                    sx={{ flexGrow: 1, paddingBottom: "4px" }}
                                    value={commissionMsg}
                                    onChange={(e) => {setCommissionMsg(e.target.value)}}
                                    />
                                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                    <Button
                                    endIcon={<PriceChangeIcon sx={{ paddingBottom: "2px" }} />}
                                    onClick={handleAddCommission}
                                    >
                                        Add
                                    </Button>
                                </Paper>
                            </Box>
                            <DataGrid
                            columns={columns}
                            rows={rows}
                            sortModel={[{ field: "createdAt", sort: 'desc' }]}
                            autoHeight
                            onRowClick={(e) => {setSelectedRow(e.row)}}
                            disableRowSelectionOnClick
                            sx={{ marginBottom: 4 }}
                            getRowClassName={(params: any) => params?.row?.status === 2 ? "requested" : ""}
                            initialState={{
                                pagination: {
                                  paginationModel: { pageSize: 7, page: 0 },
                                },
                              }}
                            />
                        </Stack>
                    </Box>
                </Stack>
            }
            <Dialog
            onClose={() => {setConfirmPanel(false)}}
            open={confirmPanel}
            >
                <DialogTitle sx={{ textAlign: "center" }}>
                    <Typography variant="h6" sx={{ paddingBottom: 1 }}>
                        Warning! You can not recover deleted submissions
                    </Typography>
                    <Typography>
                        Please Confirm you want to delete:
                    </Typography>
                    <Paper sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", height: "28px" }}>
                            <Typography variant="subtitle2" sx={{ flex: 0.4, overflowX: "scroll", whiteSpace: "nowrap" }}>
                                Amount
                            </Typography>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <Typography variant="subtitle2" sx={{ flex: 1, overflowX: "scroll", whiteSpace: "nowrap" }}>
                                Message
                            </Typography>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <Typography variant="subtitle2" sx={{ flex: 0.5, overflowX: "scroll", whiteSpace: "nowrap" }}>
                                Date
                            </Typography>
                        </Box>
                        <Divider />
                        <Box sx={{ display: "flex" }}>
                            <Typography sx={{ flex: 0.4, overflowX: "scroll", whiteSpace: "nowrap" }}>
                                {selectedRow?.amount}
                            </Typography>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <Typography sx={{ flex: 1, overflowX: "scroll", whiteSpace: "nowrap" }}>
                                {selectedRow?.message}
                            </Typography>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <Typography sx={{ flex: 0.5, overflowX: "scroll", whiteSpace: "nowrap"}}>
                                {selectedRow?.createdAt}
                            </Typography>
                        </Box>
                    </Paper>
                </DialogTitle>
                <Stack
                direction="row"
                gap={2}
                sx={{ justifyContent: "center", paddingTop: "16px", paddingBottom: "56px" }}
                >
                    <Button size="large" variant="contained" onClick={handleDeleteCommission}>Confirm</Button>
                    <Button onClick={() => {setConfirmPanel(false)}} size="large" variant="outlined">Cancel</Button>
                </Stack>
            </Dialog>
        </Box>
    )
}

export default User