import { Alert, Box, Button, Checkbox, Dialog, DialogTitle, Divider, FormControlLabel, Grow, IconButton, InputAdornment, InputBase, LinearProgress, Paper, TextField, Tooltip, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { DataGrid } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { useEffect, useState } from "react"
import { addUsers, createUser, deleteUsers, getAdminUsers, getPendingUsers, getUsers } from "../Api/User_api"
import { IUser, useUser } from "../Context/User_context"
import { NumericFormat } from "react-number-format"
import validator from "validator"
import { getSettings } from "../Api/Settings_api"
import { Search, Settings } from "@mui/icons-material"
import SettingsPanel from "../Components/SettingsPanel"
import DeleteUserPanel from "../Components/DeleteUserPanel"
import AddUserPanel from "../Components/AddUserPanel"
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend"

interface IRow {
    id: number, 
    lname: string, 
    fname: string, 
    email: string,
}

interface IPendingUser {
    id: number,
    email: string,
}

const ManageUsers = () => {

    const { setNotification } = useUser()
    const navigate = useNavigate()
    const [windowSize, setWindowSize] = useState<number>(window.innerWidth)

    const [searchString, setSearchString] = useState("")
    const [adminSearchString, setAdminSearchString] = useState("")
    const [selectedRows, setSelectedRows] = useState<Array<number | string>>([])
    const [selectedUsers, setSelectedUsers] = useState<Array<any>>([])
    const [visibleRows, setVisibleRows] = useState<IRow[]>([])
    const [rows, setRows] = useState<IRow[]>([])
    const [visibleAdminRows, setVisibleAdminRows] = useState<IRow[]>([])
    const [adminRows, setAdminRows] = useState<IRow[]>([])
    const [pendingUsers, setPendingUsers] = useState<IPendingUser[]>([])

    const [panelType, setPanelType] = useState("")

    const columns = [
        { 
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.1,
        },
        {
            field: "email",
            headerName: "email",
            flex: 1,
        },
        {
            field: "fname",
            headerName: "First name",
        },
        {
            field: "lname",
            headerName: "Last name",
        },
        {
            field: "tier",
            headerName: "Tier",
        },
        {
            field: "tierProgress",
            headerName: "Tier progress",
            renderCell: (params: any) => (
                <Stack direction="row" gap={1} sx={{ width: "100%", alignItems: "center" }}>
                    <LinearProgress variant="determinate" value={params.value ? params.value.val : 0} sx={{ flexGrow: 1 }} />
                    <Typography>
                        {`${Math.floor(params.value ? params.value.val : 0)}%`}
                    </Typography>
                </Stack>
            ),
            sortComparator: (v1: any, v2: any) => (v1 ? v1.val : 0) > (v2 ? v2.val : 0) ? 1 : -1,
        },
        {
            field: "accumulated",
            headerName: "Revenue generated",
            flex: 0.4,
        },
        {
            field: "cashed_out",
            headerName: "Cashed Out",
        },
        { 
            field: "pending",
            renderHeader: () => null,
            renderCell: (params: any) => (
                <Stack direction="row" gap={1} sx={{ width: "100%", alignItems: "center" }}>
                    {params?.value ?
                        <IconButton onClick={() => {setPanelType("delete")}}>
                            <CancelScheduleSendIcon />
                        </IconButton>
                    :
                        <IconButton onClick={() => {setPanelType("settings")}}>
                            <Settings />
                        </IconButton>
                    }
                </Stack>
            ),
            flex: 0.1,
        },
    ]

    const adminColumns = [
        { 
            field: "id",
            headerName: "ID",
            editable: false,
            flex: 0.1,
        },
        {
            field: "email",
            headerName: "email",
            flex: 1,
        },
        {
            field: "fname",
            headerName: "First name",
        },
        {
            field: "lname",
            headerName: "Last name",
        },
        { 
            field: "pending",
            renderHeader: () => null,
            renderCell: (params: any) => (
                <Stack direction="row" gap={1} sx={{ width: "100%", alignItems: "center" }}>
                    {params?.value ?
                        <IconButton onClick={() => {setPanelType("delete")}}>
                            <CancelScheduleSendIcon />
                        </IconButton>
                    :
                        <IconButton onClick={() => {setPanelType("settings")}}>
                            <Settings />
                        </IconButton>
                    }
                </Stack>
            ),
            flex: 0.1,
        },
    ]
    
    useEffect(() => {
        getPendingUsers().then(pending => {
            getUsers().then(result => {
                getAdminUsers().then(admins => {
                    if (result.type !== "error") {
                        setVisibleRows([...pending.map((user: any) => ({...user, email: `[${user.email}] pending`, pending: true})), ...result.users.map((user: any) => (
                            { ...user, pending: false, accumulated: user.accumulated.toFixed(2), tier: Math.floor(user.commission / parseFloat(result.tierAmt)), tierProgress: { val: ((user.commission % parseFloat(result.tierAmt)) / parseFloat(result.tierAmt)) * 100 } }
                        ))])
                        setRows([...pending.map((user: any) => ({...user, email: `[${user.email}]pending`, pending: true})), ...result.users.map((user: any) => (
                            { ...user, pending: false, accumulated: user.accumulated.toFixed(2), tier: Math.floor(user.commission / parseFloat(result.tierAmt)), tierProgress: { val: ((user.commission % parseFloat(result.tierAmt)) / parseFloat(result.tierAmt)) * 100 } }
                        ))])
                        setVisibleAdminRows(admins)
                        setAdminRows(admins)
                    }
                })
            })
        })
    },[pendingUsers])

    useEffect(() => {
        getPendingUsers().then(pending => {
            getUsers().then(result => {
                getAdminUsers().then(admins => {
                    if (result.type !== "error") {
                        setVisibleRows([...pending.map((user: any) => ({...user, email: `[${user.email}] pending`, pending: true})), ...result.users.map((user: any) => (
                            { ...user, pending: false, accumulated: user.accumulated.toFixed(2), tier: Math.floor(user.commission / parseFloat(result.tierAmt)), tierProgress: { val: ((user.commission % parseFloat(result.tierAmt)) / parseFloat(result.tierAmt)) * 100 } }
                        ))])
                        setRows([...pending.map((user: any) => ({...user, email: `[${user.email}]pending`, pending: true})), ...result.users.map((user: any) => (
                            { ...user, pending: false, accumulated: user.accumulated.toFixed(2), tier: Math.floor(user.commission / parseFloat(result.tierAmt)), tierProgress: { val: ((user.commission % parseFloat(result.tierAmt)) / parseFloat(result.tierAmt)) * 100 } }
                        ))])
                        setVisibleAdminRows(admins.map((admin: any, index: number) => ({id: index, ...admin})))
                        setAdminRows(admins.map((admin: any, index: number) => ({id: index, ...admin})))
                    }
                })
            })
        })
    },[])

    useEffect(() => {
        setSelectedUsers(selectedRows.map(id => {
            return rows.find(user => user.id === id) ?? adminRows.find(user => user.id === id)
        }))
    }, [selectedRows])

    useEffect(() => {
        const reg = new RegExp(searchString, "i")
        setVisibleRows(rows.filter(row => reg.test(row.email)))
    }, [searchString])

    useEffect(() => {
        const reg = new RegExp(adminSearchString, "i")
        setVisibleAdminRows(adminRows.filter(row => reg.test(row.email)))
    }, [adminSearchString])

    useEffect(() => {
        window.addEventListener("resize", () => {setWindowSize(window.innerWidth)})

        return () => {
            window.removeEventListener("resize", () => {setWindowSize(window.innerWidth)})
        }
    })

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "16px" }}>
            <Stack gap={3} sx={{ paddingTop: 3 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: { xs: "start", md: "end" }, flexDirection: { xs: "column", md: "row" } }}>
                    <Typography variant="h3">
                        Members
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, alignItems: "center", flexDirection: { xs: "column-reverse", md: "row" } }}>
                        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: { xs: "100%", md: 400 }, height: 33 }}>
                            <InputBase
                            placeholder="Search email"
                            sx={{ flexGrow: 1, paddingBottom: "4px", paddingLeft: 1 }}
                            value={searchString}
                            onChange={(e) => {setSearchString(e.target.value)}}
                            />
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton>
                                <Search sx={{ paddingBottom: "2px" }} />
                            </IconButton>
                        </Paper>
                        <Box sx={{ display: "flex", gap: 1, width: { xs: "100%", md: "auto" }, paddingTop: { xs: 2, md: 0 } }}>
                            <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }} />
                            <Button
                            variant="contained"
                            endIcon={<AddCircleOutlineIcon />}
                            onClick={() => {setPanelType("add")}}
                            >
                                add user
                            </Button>
                            <Button
                            disabled={selectedUsers.length === 0}
                            variant="contained"
                            onClick={() => {setPanelType("delete")}}
                            >
                                delete
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <DataGrid
                columns={columns}
                rows={visibleRows}
                autoHeight
                onRowSelectionModelChange={(rows) => {setSelectedRows(rows)}}
                rowSelectionModel={selectedRows}
                onCellClick={(e) => {(e.field === "pending") || (e.field === "__check__") ? setSelectedRows([e.row.id]) : !e.row.pending && navigate(`/manage/${e.row.id}`)}}
                checkboxSelection
                disableRowSelectionOnClick
                getRowClassName={(params: any) => params?.row?.pending ? "disabled-row" : "enabled-row"}
                columnVisibilityModel={{
                    id: windowSize < 900 ? false : true,
                    fname: windowSize < 900 ? false : true,
                    lname: windowSize < 900 ? false : true,
                    tier: windowSize < 900 ? false : true,
                    tierProgress: windowSize < 900 ? false : true,
                    accumulated: windowSize < 900 ? false : true,
                    cashed_out: windowSize < 900 ? false : true,
                }}
                />
            </Stack>
            <Stack gap={3} sx={{ paddingTop: 3 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: { xs: "start", md: "end" }, flexDirection: { xs: "column", md: "row" } }}>
                    <Typography variant="h3">
                        Admins
                    </Typography>
                    <Box sx={{ display: "flex", gap: 3, alignItems: "center", flexDirection: { xs: "column-reverse", md: "row" } }}>
                        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: { xs: "100%", md: 400 }, height: 33 }}>
                            <InputBase
                            placeholder="Search email"
                            sx={{ flexGrow: 1, paddingBottom: "4px", paddingLeft: 1 }}
                            value={adminSearchString}
                            onChange={(e) => {setAdminSearchString(e.target.value)}}
                            />
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton>
                                <Search sx={{ paddingBottom: "2px" }} />
                            </IconButton>
                        </Paper>
                        <Box sx={{ display: "flex", gap: 1, width: { xs: "100%", md: "auto" }, paddingTop: { xs: 2, md: 0 } }}>
                            <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }} />
                            <Button
                            variant="contained"
                            endIcon={<AddCircleOutlineIcon />}
                            onClick={() => {setPanelType("addAdmin")}}
                            >
                                add admin
                            </Button>
                            <Button
                            disabled={selectedUsers.length === 0}
                            variant="contained"
                            onClick={() => {setPanelType("delete")}}
                            >
                                delete
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <DataGrid
                columns={adminColumns}
                rows={visibleAdminRows}
                autoHeight
                onRowSelectionModelChange={(rows) => {setSelectedRows(rows)}}
                rowSelectionModel={selectedRows}
                onCellClick={(e) => {((e.field === "pending") || (e.field === "__check__")) && setSelectedRows([e.row.id])}}
                checkboxSelection
                disableRowSelectionOnClick
                getRowClassName={(params: any) => params?.row?.pending ? "disabled-row" : "enabled-row"}
                columnVisibilityModel={{
                    id: windowSize < 900 ? false : true,
                    fname: windowSize < 900 ? false : true,
                    lname: windowSize < 900 ? false : true,
                    tier: windowSize < 900 ? false : true,
                    tierProgress: windowSize < 900 ? false : true,
                    accumulated: windowSize < 900 ? false : true,
                    cashed_out: windowSize < 900 ? false : true,
                }}
                />
            </Stack>
            {selectedUsers.length > 0 && 
            <SettingsPanel
            user={selectedUsers[selectedUsers.length - 1]}
            open={panelType === "settings"}
            onClose={() => {setPanelType("")}}
            onEdit={(user: any) => {
                setPanelType("")
                // const editedUser = rows.filter(user => selectedRows.find(id => id === user.id))[0]
                if (rows.find(rowUser => rowUser.email === user.email)) {
                    setRows([...rows.filter(user => !selectedRows.find(id => id === user.id)), user])
                    setVisibleRows([...visibleRows.filter(user => !selectedRows.find(id => id === user.id)), user])
                } else {
                    setAdminRows([...rows.filter(user => !selectedRows.find(id => id === user.id)), user])
                    setVisibleAdminRows([...visibleAdminRows.filter(user => !selectedRows.find(id => id === user.id)), user])
                }
            }}
            />}
            <DeleteUserPanel
            users={selectedUsers}
            open={panelType === "delete"}
            onClose={() => {setPanelType("")}}
            onDelete={() => {
                setPanelType("")
                setRows(rows.filter(user => !selectedRows.find(id => id === user.id)))
                setVisibleRows(visibleRows.filter(user => !selectedRows.find(id => id === user.id)))
                setAdminRows(adminRows.filter(user => !selectedRows.find(id => id === user.id)))
                setVisibleAdminRows(visibleAdminRows.filter(user => !selectedRows.find(id => id === user.id)))
            }}
            />
            <AddUserPanel
            isAdmin={panelType === "addAdmin"}
            pendingUsers={pendingUsers}
            open={panelType === "add" || panelType === "addAdmin"}
            onClose={() => {setPanelType("")}}
            onDelete={(id) => {
                setPendingUsers(pendingUsers.filter(user => user.id !== id))
                setRows(rows.filter(user => user.id !== id))
                setVisibleRows(visibleRows.filter(user => user.id !== id))
            }}
            onAdd={(result, initialize, email) => {
                if (!initialize) {
                    setPendingUsers([...pendingUsers, { email: email, id: result.data }])
                } else {
                    setPendingUsers([...pendingUsers])
                }
            }}
            />
        </div>
    )
}

export default ManageUsers