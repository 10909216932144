import { ExpandMore } from "@mui/icons-material"
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, Chip, FormControl, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import LaunchIcon from "@mui/icons-material/Launch"
import { useNavigate } from "react-router-dom"
import { ReactElement, useEffect, useState } from "react"
import { NumericFormat } from "react-number-format"
import { getSettings, updateSettings } from "../Api/Settings_api"
import { useUser } from "../Context/User_context"
import { getAdminUsers } from "../Api/User_api"

const Settings = () => {

    const { setNotification } = useUser()
    const navigate = useNavigate()

    const [percentVal, setPercentVal] = useState<number>()
    const [tierVal, setTierVal] = useState<string>("")
    const [payout, setPayout] = useState<string>("")
    const [adminEmail, setAdminEmail] = useState<string[]>([])
    const [overrideLimit, setOverrideLimit] = useState<number[]>([])
    const [adminList, setAdminList] = useState<[any]>()

    const handleTierValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setTierVal(value)
    }

    const handlePayOutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setPayout(value)
    }

    const handleUpdateSettings = async () => {
        const result = await updateSettings(`${tierVal}`.replace(",",""), `${payout}`.replace(",",""), adminEmail.toString(), overrideLimit)
        setNotification({ type: result.type, msg: result.msg })
    }

    useEffect(() => {
        getSettings().then(result => {
            if (result.type === "error") {
                setNotification({ type: result.type, msg: result.msg })
                navigate(-1)
            } else {
                const config = JSON.parse(result.config)
                setTierVal(config.tierAmt)
                setPayout(config.payoutAmt)
                config.notifEmail ? setAdminEmail(config.notifEmail.split(",")) : setAdminEmail([])
                config.tiers && setOverrideLimit(config.tiers)
            }
        })
        getAdminUsers().then(result => {
            if (result.type === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setAdminList(result)
            }
        })
    },[])

    interface IMenu {
        name: string,
        submenu: ISubmenu[]
    }

    interface ISubmenu {
        name: string,
        summary?: ReactElement,
        icon?: ReactElement,
    }

    const menus: IMenu[] = [
        {
            name: "Comission",
            submenu: [
                {
                    name: "Tiers",
                    summary: 
                        <Stack gap={4}>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography>
                                    Revenue required per tier:
                                </Typography>
                                <NumericFormat
                                decimalScale={2}
                                size="small"
                                customInput={TextField}
                                thousandSeparator=","
                                allowNegative={false}
                                required
                                value={tierVal}
                                onChange={handleTierValChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                >
                                </NumericFormat>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography sx={{ opacity: overrideLimit.length > 0 ? "0.5" : "1" }}>
                                    Commission payout per tier:
                                </Typography>
                                <NumericFormat
                                decimalScale={2}
                                size="small"
                                customInput={TextField}
                                thousandSeparator=","
                                allowNegative={false}
                                required
                                disabled={overrideLimit.length > 0}
                                value={payout}
                                onChange={handlePayOutChange}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                >
                                </NumericFormat>
                            </Box>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography sx={{ opacity: overrideLimit.length > 0 ? "1" : "0.5" }}>
                                    Override Global commission payout per tier:
                                </Typography>
                                <Checkbox checked={overrideLimit.length > 0} onChange={() => {setOverrideLimit(overrideLimit.length > 0 ? [] : [parseFloat(payout)])}} />
                            </Box>
                            {overrideLimit?.map((tier, index) => (
                                <>
                                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        <Typography>
                                            Tier {index + 1} commission payout:
                                        </Typography>
                                        <NumericFormat
                                        size="small"
                                        autoFocus
                                        value={tier}
                                        onBlur={(e) => {(index + 1 === overrideLimit.length) ? e.target.value === "" && setOverrideLimit(overrideLimit.slice(0,-1)) : e.target.value === "" && setOverrideLimit(overrideLimit.map((tier, tierIndex) => tierIndex === index ? 0 : tier)) }}
                                        onChange={(e) => {setOverrideLimit(overrideLimit.map((tier, tierIndex) => tierIndex === index ? parseFloat((e.target.value).replaceAll(",", "")) : tier))}}
                                        customInput={TextField}
                                        thousandSeparator=","
                                        allowNegative={false}
                                        required
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                        >
                                        </NumericFormat>
                                    </Box>
                                    {index + 1 === overrideLimit.length &&
                                        <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                            <Typography sx={{ opacity: 0.5 }}>
                                                Tier {index + 2} commission payout:
                                            </Typography>
                                            <NumericFormat
                                            sx={{ opacity: 0.5 }}
                                            size="small"
                                            value={tier}
                                            onFocus={(e) => {setOverrideLimit([...overrideLimit, tier])}}
                                            onChange={(e) => {setOverrideLimit([...overrideLimit, tier])}}
                                            customInput={TextField}
                                            thousandSeparator=","
                                            allowNegative={false}
                                            required
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            }}
                                            >
                                            </NumericFormat>
                                        </Box>
                                    }
                                </>
                            ))}
                        </Stack>
                },
                {
                    name: "Users",
                    icon: <LaunchIcon />,
                },
            ],
        },
        {
            name: "Admin",
            submenu: [
                {
                    name: "Notifications",
                    summary: 
                        <Stack gap={4}>
                            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Typography>
                                    Email to notify:
                                </Typography>
                                {/* <TextField
                                size="small"
                                required
                                value={adminEmail}
                                placeholder="notify@email.com"
                                onChange={(e) => {setAdminEmail(e.target.value)}}
                                >
                                </TextField> */}
                                <FormControl>
                                    <InputLabel>
                                        email
                                    </InputLabel>
                                    <Select
                                    sx={{ minWidth: "230px" }}
                                    multiple
                                    value={[...adminEmail]}
                                    onChange={(e) => {console.log(e); typeof e.target.value === "string" ? setAdminEmail(e.target.value.split(",")) : setAdminEmail(e.target.value) }}
                                    input={<OutlinedInput label="Tag" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                                            {selected.map((value) => (
                                                <Chip label={value} />
                                            ))}
                                        </Box>
                                    )}
                                    >
                                        {adminList && adminList.map((admin) => (
                                            <MenuItem value={admin.email}>
                                                <Checkbox checked={adminEmail.includes(admin.email)} />
                                                <ListItemText primary={admin.email} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Stack>
                },
                {
                    name: "List of Admins",
                    summary: 
                        <Stack gap={4}>
                            {adminList && adminList.map(user => (
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <Typography>
                                        {user.email}
                                    </Typography>
                                    <Box sx={{ width: "230px" }}>
                                        <Typography sx={{ paddingLeft: "14px" }}>
                                            {`${user.fname} ${user.lname}`}
                                        </Typography>
                                    </Box>
                                </Box>
                            ))}
                        </Stack>
                },
            ],
        },
    ]

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "16px" }}>
            <Stack gap={3} sx={{ paddingTop: 3, paddingBottom: 3 }}>
                <Typography variant="h3">
                    Settings
                </Typography>
                {menus.map((item) => (
                    <Stack>
                        <Typography variant="h5" sx={{ paddingBottom: 1 }}>
                            {item.name}
                        </Typography>
                        {item?.submenu.map((subitem) => (
                            <Accordion
                            onClick={() => {subitem.icon && navigate("/manage")}}
                            >
                                <AccordionSummary
                                expandIcon={subitem.icon ?? <ExpandMore />}
                                >
                                    <Typography>
                                        {subitem.name}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {subitem.summary}
                                </AccordionDetails>
                            </Accordion>
                        ))}
                    </Stack>
                ))}
            </Stack>
            <Box sx={{ display: "flex" }}>
                <Box sx={{ flexGrow: 1 }} />
                <Button disabled={tierVal.length === 0 || payout.length === 0 || adminEmail.length === 0} onClick={handleUpdateSettings} variant="contained" size="large">update</Button>
            </Box>
        </div>
    )
}

export default Settings