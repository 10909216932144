import "./global.css"

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import UserProvider from "./Context/User_context"
import Layout from "./Pages/Layout"
import ManageUsers from "./Pages/ManageUsers"
import Home from "./Pages/Home"
import Settings from "./Pages/Settings"
import User from "./Pages/User"
import Account from "./Pages/Account"
import Login from "./Pages/Login"
import Register from "./Pages/Register"
import ProtectedRoute from "./Components/ProtectedRoute"
import AdminRoute from "./Components/AdminRoute"
import PublicRoute from "./Components/PublicRoute"
import Audit from "./Pages/Audit"
import ResetPassword from "./Pages/ResetPassword"

function App() {
  return (
    <UserProvider>
      <BrowserRouter>
          <Routes>
            <Route path="/" element={ <Layout /> }>
              <Route element={ <ProtectedRoute /> }>
                <Route element={ <AdminRoute /> }>
                  <Route path="/audit" element={ <Audit /> } />
                  <Route path="/manage" element={ <ManageUsers /> } />
                  <Route path="/manage/:userid" element={ <User /> } />
                  <Route path="/settings" element={ <Settings /> } />
                </Route>
                <Route index element={ <Home /> } />
                <Route path="/account" element={ <Account /> } />
              </Route>
              <Route element={ <PublicRoute /> }>
                <Route path="/login" element={ <Login /> } />
                <Route path="/register/:token" element={ <Register /> } />
                <Route path="/reset/:token" element={ <ResetPassword /> } />
              </Route>
            </Route>
            <Route path="*" element={ <Navigate to="/" />  } />
          </Routes>
      </BrowserRouter>
    </UserProvider>
  );
}

export default App
