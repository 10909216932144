import { Alert, AlertColor, Box, LinearProgress, Snackbar, Typography } from "@mui/material"
import { Container } from "@mui/system"
import { PropsWithChildren, useEffect, useState } from "react"
import { Outlet, useLocation } from 'react-router-dom'
import Header from "../Components/Header"
import { useUser } from "../Context/User_context"
import { CSSTransition, SwitchTransition, TransitionGroup } from "react-transition-group"

const Layout = (props: PropsWithChildren) => {

    const { notification, setNotification } = useUser()
    const location = useLocation()

    const [progress, setProgress] = useState<number>(0)

    useEffect(() => {
        if (notification) {
            setProgress(100)
        }
    }, [notification])

    useEffect(() => {
        if (progress) {
            const timer = setInterval(() => {
                setProgress((prevProgress) => (prevProgress - 25))
            }, 800)
    
            if (progress <= 0) {
                clearInterval(timer)
            }
    
            return () => {
            clearInterval(timer)
            }
        }
    }, [progress])

    return (
        <>
            <Header />
            <Container fixed sx={{ paddingBottom: 4, overflowX: "hidden" }}>
                <Outlet />
            </Container>
            <Snackbar
            open={Boolean(notification)}
            autoHideDuration={4000}
            onClose={() => {setNotification(undefined)}}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Box>
                    <Alert
                    sx={{ width: { xs: "87vw", md: "400px" } }}
                    severity={notification?.type as AlertColor}
                    >
                        <Typography
                        variant='caption'
                        >
                            {notification?.msg}
                        </Typography>
                        <LinearProgress
                        variant='determinate'
                        value={progress}
                        sx={{ width: '360px' }}
                        />
                    </Alert>
                </Box>
            </Snackbar>
        </>
    )
}

export default Layout