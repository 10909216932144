import Alert from "@mui/material/Alert"
import Button from "@mui/material/Button"
import Grow from "@mui/material/Grow"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { useState } from "react"
import { resetPassword } from "../Api/User_api"
import { useUser } from "../Context/User_context"
import { Navigate, useNavigate, useParams } from "react-router-dom"

const ResetPassword = () => {

    const { setNotification } = useUser()
    const navigate = useNavigate()
    const { token } = useParams()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [alert, setAlert] = useState<string | undefined>()

    const handleReset = async () => {
        const result = await resetPassword(password, token ?? "")
        if (result.type === "error") {
            setAlert(result.msg)
        } else {
            setNotification({ type: result.type, msg: result.msg })
            navigate("/")
        }
    }

    return (
        <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <Grow in={Boolean(alert)}>
                <Alert severity="error" sx={{ marginTop: 6, width: "230px" }}>{alert}</Alert>
            </Grow>
            <Paper sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, paddingTop: 2, paddingRight: 2, paddingBottom: 4, paddingLeft: 2, marginTop: 2 }}>
                <Typography variant="h4">
                    Reset password.
                </Typography>
                <TextField
                label="Password"
                size="small"
                type="password"
                value={password}
                onChange={(e) => {setPassword(e.target.value)}}
                />
                <TextField
                label="Repeat Password"
                size="small"
                type="password"
                value={password}
                onChange={(e) => {setPassword(e.target.value)}}
                />
                <Button onClick={handleReset} variant="contained">
                    reset password
                </Button>
            </Paper>
        </Stack>
    )
}

export default ResetPassword