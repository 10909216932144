import { CircularProgress, Box, Stack, Typography } from "@mui/material"
import { PropsWithChildren } from "react"
import { IUser } from "../Context/User_context"

interface IUserDataProps {
    userData: IUser
}

const ProgressAvatar = (props: IUserDataProps) => {

    const { fname, lname, commission, tierAmt } = props.userData

    return (
        <Stack gap={1} sx={{ alignItems: "center" }}>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress thickness={6} sx={{ color: "rgb(255, 255, 0)", mixBlendMode: "difference" }} variant="determinate" value={((commission % tierAmt) / tierAmt) * 100} size="15rem" />
                <Box
                    sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#f6f6f6",
                    zIndex: "-1",
                    borderRadius: "100%",
                    }}
                >
                    {/* <Typography variant="h1" component="div">
                        {Math.floor(commission / tierAmt)}
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        {Math.floor(((commission % tierAmt) / tierAmt) * 100)}%
                    </Typography> */}
                    <Typography variant="h5" component="div" sx={{ paddingTop: 9 }}>
                        ${(commission % tierAmt).toFixed(2)}
                    </Typography>
                    <Typography variant="h6" component="div">
                        current tier revenue
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        ${tierAmt - (commission % tierAmt)} more to go
                    </Typography>
                    <Typography variant="subtitle1" component="div">
                        for tier {Math.floor(commission / tierAmt) + 1}
                    </Typography>
                    <Typography variant="h5" sx={{ fontWeight: "bold", paddingTop: 2, color: "rgb(0, 0, 255)" }} component="div">
                        {Math.floor(((commission % tierAmt) / tierAmt) * 100)}%
                    </Typography>
                </Box>
            </Box>
            <Stack sx={{ alignItems: "center" }}>
                <Typography variant="h4">
                    {`${fname}, ${lname}`}
                </Typography>
                <Typography variant="h6">
                    Tier {Math.floor(commission / tierAmt)}
                </Typography>
            </Stack>
        </Stack>    
    )
}

export default ProgressAvatar