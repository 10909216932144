import { PropsWithChildren, createContext, useContext, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { auth, login as loginAPI, logout as logoutAPI, register, changePassword as changePasswordAPI } from "../Api/User_api"
import User from "../Pages/User"

interface IValue {
    user: IUser | undefined | null,
    setNotification: (newNotification: INotification | undefined) => void,
    //<any>??
    login: (email: string, password: string) => Promise<any>,
    register: (fname: string, lname: string, password: string, token: string) => Promise<any>,
    logout: () => Promise<any>,
    changePassword: (passwordCurr: string, password: string) => Promise<any>,
    notification?: INotification,
    refreshInfo: () => Promise<any>
}

interface INotification {
    type: string,
    msg: string,
}

export interface IUser {
    id: number,
    email: string,
    fname: string,
    lname: string,
    tier: number,
    accumulated: number,
    commission: number,
    cashed_out: number,
    status: number,
    tierAmt: number,
    payoutAmt: number,
    tiers: number[]
}

const defaultUser = {
    user: undefined,
    //bruh.
    setNotification: () => {},
    login: loginAPI,
    logout: logoutAPI,
    register: register,
    changePassword: changePasswordAPI,
    refreshInfo: auth,
}

const UserContext = createContext<IValue>(defaultUser)

export const useUser = () => (
    useContext(UserContext)
)

const UserProvider = (props: PropsWithChildren) => {

    const [notification, setNotificationInternal] = useState<INotification | undefined>()
    const [user, setUserInternal] = useState<IUser | null | undefined>()

    const setUser = (newUser: IUser) => {
        setUserInternal(newUser)
    }

    const setNotification = (newNotification: INotification | undefined) => {
        setNotificationInternal(newNotification)
    }

    const refreshInfo: any = async () => {
        const result = await auth()
        if (result.type !== "error") {
            setUserInternal(result)
        }
        return result
    }

    const login: any = async (email: string, password: string) => {
        const result = await loginAPI(email, password)
        if (result.type !== "error") {
            setUserInternal(result)
        }
        return result
    }
    
    const logout: any = async () => {
        const result = await logoutAPI()
        if (result.type === "success") {
            setUserInternal(null)
        }
        return result
    }

    const changePassword: any = async (passwordCurr: string, password: string) => {
        const result = await changePasswordAPI(passwordCurr, password)
        if (result.type === "success") {
            setNotificationInternal(result)
        }
        return result
    }

    useEffect(() => {
        auth().then(result => {
            if (result.type !== "error") {
                setUserInternal(result)
            } else {
                setUserInternal(null)
            }
        })
    },[])

    return (
        <UserContext.Provider value={{ user, refreshInfo, login, logout, register, changePassword, notification, setNotification }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserProvider