
const CsvGenerate = (data: any) => {
    let csvContent = "data:text/csv;charset=utf-8," 
    csvContent += Object.keys(data[0]).join(",")

    csvContent += data.map((row: any, index: number) => (
        "\n" + Object.values(row).map((item: any) => `${item}`.replace(/,/g, ""))
    ))

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement("a")
    link.setAttribute("href", encodedUri)
    link.setAttribute("download", `audit_log_${new Date().toISOString().slice(0, 10)}.csv`)
    document.body.appendChild(link)
    link.click()
}

export default CsvGenerate