import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Divider from "@mui/material/Divider"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { deleteUsers } from "../Api/User_api"
import { useUser } from "../Context/User_context"

interface IUserProp {
    email: string,
    id: number,
}

interface IUsersProp {
    users: IUserProp[],
    onClose: () => void,
    onDelete: () => void,
    open: boolean
}

const DeleteUserPanel = (props: IUsersProp) => {

    const { setNotification } = useUser()

    const { users } = props

    const handleDeleteUsers = async () => {
        const result = await deleteUsers(users.map(user => user.id))
        setNotification({ type: result.type, msg: result.msg })
        props.onDelete()
    }

    return (
        <Dialog
        onClose={props.onClose}
        open={props.open}
        >
            <DialogTitle sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ paddingBottom: 1 }}>
                    You are about to delete user(s)
                </Typography>
                <Typography>
                    Please Confirm you want to delete.
                </Typography>
                <Paper sx={{ display: "flex", flexDirection: "column", marginTop: 1, alignItems: "flex-start" }}>
                    {users.map((row) => (
                        <Box>
                            <Typography sx={{ overflowX: "scroll", padding: 1 }}>
                                {row.email}
                            </Typography>
                            <Divider />
                        </Box>
                    ))}
                </Paper>
            </DialogTitle>
            <Stack
            direction="row"
            gap={2}
            sx={{ justifyContent: "center", paddingTop: "16px", paddingBottom: "56px" }}
            >
                <Button onClick={handleDeleteUsers} size="large" variant="contained">Confirm</Button>
                <Button onClick={props.onClose} size="large" variant="outlined">Cancel</Button>
            </Stack>
        </Dialog>
    )
}

export default DeleteUserPanel