import { Alert, Button, Grow, Paper, TextField, Typography } from "@mui/material"
import { useState } from "react"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import { Stack } from "@mui/system"
import { register } from "../Api/User_api"
import { useNavigate, useParams } from "react-router-dom"
import { useUser } from "../Context/User_context"

const Register = () => {

    const { setNotification } = useUser()
    const navigate = useNavigate()
    const { token } = useParams()

    const [fname, setFname] = useState("")
    const [lname, setLname] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [alert, setAlert] = useState<string | undefined>()

    const handleRegister = async () => {
        if (password !== confirmPassword) {
            setAlert("Passwords do not match")
        } else {
            if (token) {
                const result = await register(fname, lname, confirmPassword, token)
                if (result.type === "error") {
                    setAlert(result.msg)
                } else {
                    setNotification({ type: result.type, msg: result.msg })
                    navigate("/")
                } 
            }
        }
    }

    return (
        <Stack sx={{ justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <Grow in={Boolean(alert)}>
                <Alert severity="error" sx={{ marginTop: 8, width: "230px" }}>{alert}</Alert>
            </Grow>
            <Paper sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2, paddingTop: 2, paddingRight: 2, paddingBottom: 4, paddingLeft: 2, marginTop: 2 }}>
                <AccountCircleIcon sx={{ fontSize: "4.5rem" }} />
                <Typography variant="h4">
                    Register.
                </Typography>
                <TextField
                label="First name"
                size="small"
                onChange={(e) => {setFname(e.target.value)}}
                value={fname}
                />
                <TextField
                label="Last name"
                size="small"
                onChange={(e) => {setLname(e.target.value)}}
                value={lname}
                />
                <TextField
                label="Password"
                size="small"
                type="password"
                onChange={(e) => {setPassword(e.target.value)}}
                value={password}
                />
                <TextField
                label="Confirm Password"
                disabled={password.length === 0}
                size="small"
                type="password"
                onChange={(e) => {setConfirmPassword(e.target.value)}}
                value={confirmPassword}
                />
                <Button
                variant="contained"
                onClick={handleRegister}
                disabled={confirmPassword.length === 0 || password.length === 0}
                >
                    Register
                </Button>
            </Paper>
        </Stack>
    )
}

export default Register