import { Box, Button, Divider, IconButton, InputBase, Paper, Stack, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { getAuditLogs } from "../Api/Log_api"
import { useUser } from "../Context/User_context"
import { Search } from "@mui/icons-material"
import CsvGenerate from "../Handlers/CsvGenerate"

const Audit = () => {

    const { setNotification } = useUser()
    const [rows, setRows] = useState([])
    const [visibleRows, setVisibleRows] = useState([])
    const [searchString, setSearchString] = useState("")

    const columns = [
        {
            field: "id",
            headerName: "ID",
            editable: false,
        },
        {
            field: "user_id",
            headerName: "user id",
            editable: false,
        },
        {
            field: "action",
            headerName: "action",
            editable: false,
        },
        {
            field: "message",
            headerName: "message",
            editable: false,
            flex: 1,
        },
        {
            field: "createdAt",
            headerName: "date",
            editable: false,
            flex: 0.3,
        },
    ]
 
    useEffect(() => {
        getAuditLogs().then(result => {
            if (result.type === "error") {
                setNotification({ type: result.type, msg: result.msg })
            } else {
                setRows(result.map((audit: any) => ({...audit, createdAt:new Date(audit.createdAt).toLocaleString() })))
                setVisibleRows(result.map((audit: any) => ({...audit, createdAt: new Date(audit.createdAt).toLocaleString()})))
            }
        })
    }, [])

    useEffect(() => {
        const reg = new RegExp(searchString, "i")
        setVisibleRows(rows.filter((row: any) => reg.test(row.message) || reg.test(row.action) || reg.test(row.createdAt)))
    }, [searchString])

    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "16px" }}>
            <Stack gap={3} sx={{ paddingTop: 3 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: { xs: "start", md: "end" }, flexDirection: { xs: "column", md: "row" } }}>
                    <Typography variant="h3">
                        Audit log
                    </Typography>
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                    size="small"
                    onClick={() => {CsvGenerate(visibleRows)}}
                    >
                        export to csv
                    </Button>
                    <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: { xs: "100%", md: 400 }, height: 33 }}>
                        <InputBase
                        placeholder="Search"
                        sx={{ flexGrow: 1, paddingBottom: "4px", paddingLeft: 1 }}
                        value={searchString}
                        onChange={(e) => {setSearchString(e.target.value)}}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton>
                            <Search sx={{ paddingBottom: "2px" }} />
                        </IconButton>
                    </Paper>
                </Box>
                <DataGrid
                autoHeight
                columns={columns}
                rows={visibleRows}
                sx={{ marginBottom: 4 }}
                sortModel={[{ field: "createdAt", sort: "desc" }]}
                />
            </Stack>
        </div>
    )
}

export default Audit