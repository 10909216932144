import { Navigate, Outlet } from "react-router-dom"
import { useUser } from "../Context/User_context"

const AdminRoute = () => {
    const { user } = useUser()

    if (user?.status === 2) {
        return (<Outlet />)
    } else {
        return (<Navigate to="/" replace />)
    }
}

export default AdminRoute