import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import DialogTitle from "@mui/material/DialogTitle"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Tooltip from "@mui/material/Tooltip"
import Typography from "@mui/material/Typography"
import { useEffect, useState } from "react"
import validator from "validator"
import { useUser } from "../Context/User_context"
import { addUsers, createUser, deleteUsers } from "../Api/User_api"
import IconButton from "@mui/material/IconButton"
import Alert from "@mui/material/Alert"
import { NumericFormat } from "react-number-format"
import InputAdornment from "@mui/material/InputAdornment"
import Divider from "@mui/material/Divider"
import InputBase from "@mui/material/InputBase"
import TextField from "@mui/material/TextField"
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox"
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend"
import Grow from "@mui/material/Grow"
import Checkbox from "@mui/material/Checkbox"
import LibraryAddIcon from "@mui/icons-material/LibraryAdd"
import { Button } from "@mui/material"

interface IUsersProps {
    pendingUsers: any,
    open: boolean,
    onClose: () => void,
    onDelete: (id: number) => void,
    onAdd: (result: any, initialize: boolean, email: string) => void,
    isAdmin: boolean,
}

const AddUserPanel = (props: IUsersProps) => {

    const { setNotification } = useUser()

    const { pendingUsers, isAdmin } = props

    useEffect(() => {
        setNewUserAdmin(isAdmin)
    }, [isAdmin])

    const [newUser, setNewUser] = useState("")
    const [newUserAdmin, setNewUserAdmin] = useState(isAdmin)
    const [commissionVal, setCommissionVal] = useState("0")
    const [fnameVal, setFnameVal] = useState("")
    const [lnameVal, setLnameVal] = useState("")
    const [passwordVal, setPasswordVal] = useState("")
    const [errorMsg, setErrorMsg] = useState<undefined | string>()
    const [loading, setLoading] = useState(false)
    const [initializeAccount, setInitializeAccount] = useState(false)

    const handleAddUsers = async () => {
        if (validator.isEmail(newUser)) {
            setLoading(true)
            if (!initializeAccount) {
                const result = await addUsers(newUserAdmin, parseFloat(commissionVal.replace(",", "")), newUser)
                setLoading(false)
                if (result.type === "error") {
                    setNotification({ type: result.type, msg: result.msg })
                } else {
                    props.onAdd(result, false, newUser)
                    // const pendingUser = await getPendingUsers()
                    // setVisibleRows([...visibleRows, ...pendingUser.map((user: any) => ({...user, pending: { val: true } }))])
                    // setRows([...rows, ...pendingUser.map((user: any) => ({...user, pending: { val: true } }))])
                    setNotification({ type: result.type, msg: result.msg })
                    setNewUser("")
                    setFnameVal("")
                    setLnameVal("")
                    setPasswordVal("")
                    setInitializeAccount(false)
                    setNewUserAdmin(false)
                    setCommissionVal("0")
                }
            } else {
                const result = await createUser(newUserAdmin, fnameVal, lnameVal, passwordVal, parseFloat(commissionVal.replace(",", "")), newUser)
                if (result.type === "error") {
                    setNotification({ type: result.type, msg: result.msg })
                } else {
                    props.onAdd(result, true, newUser)
                    setNotification({ type: result.type, msg: result.msg })
                    setNewUser("")
                    setFnameVal("")
                    setLnameVal("")
                    setPasswordVal("")
                    setInitializeAccount(false)
                    setNewUserAdmin(false)
                    setCommissionVal("0")
                }
            }
            setLoading(false)
        } else {
            setErrorMsg("Email is not valid.")
        }
    }

    const handleDeletePendingUser = async (id: number) => {
        const result = await deleteUsers([id])
        setNotification({ type: result.type, msg: result.msg })
        props.onDelete(id)
    }

    return (
        <Dialog
        onClose={props.onClose}
        open={props.open}
        >
            <DialogTitle sx={{ textAlign: "center" }}>
                <Typography variant="h6" sx={{ paddingBottom: 1 }}>
                    {isAdmin ? "Add Admin" : "Add User"}
                </Typography>
            </DialogTitle>
            <Stack
            gap={2}
            sx={{ justifyContent: "center", paddingTop: 1, paddingBottom: 4, paddingLeft: 2, paddingRight: 2 }}
            >
                {pendingUsers.length > 0 &&
                <Stack>
                    <Typography variant="h6" sx={{ paddingBottom: 1, paddingTop: 1 }}>Pending users</Typography>
                    <Paper sx={{ display: "flex", flexDirection: "column", }}>
                        {pendingUsers.map((user: any) => (
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "4px" }}>
                                <Typography sx={{ color: "text.secondary" }}>
                                    {user.email}
                                </Typography>
                                <Tooltip title="Cancel invite">
                                    <IconButton
                                    onClick={() => {handleDeletePendingUser(user.id)}}
                                    >
                                        <CancelScheduleSendIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        ))}
                    </Paper>
                </Stack>
                }
                <Stack>
                    <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}>
                        <Typography variant="h6" sx={{ paddingBottom: 1, paddingTop: 1 }}>{isAdmin ? "Add Admin" : "Add User"}</Typography>
                        <Box sx={{ flexGrow: 1, marginLeft: 1, paddingBottom: 1 }}>
                            <Grow in={Boolean(errorMsg)}>
                                <Alert sx={{ marginBottom: "0px" }} severity="error">
                                    {errorMsg}
                                </Alert>
                            </Grow>
                        </Box>
                    </Box>
                    <Paper sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%" }}>
                        <Stack sx={{ width: "100%" }}>
                            { !isAdmin &&
                            <>
                                <Box sx={{ height: 2, m: 0.5 }} />
                                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "4px" }}>
                                    <Typography>
                                        initial commission:
                                    </Typography>
                                    <Box sx={{ flexGrow: 1 }} />
                                    <NumericFormat
                                    decimalScale={2}
                                    size="small"
                                    customInput={TextField}
                                    thousandSeparator=","
                                    allowNegative={false}
                                    required
                                    value={commissionVal}
                                    onChange={(e) => {setCommissionVal(e.target.value)}}
                                    sx={{ width: "128px", paddingRight: "4px" }}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                    }}
                                    >
                                    </NumericFormat>
                                </Box>
                            </>
                            }
                            <Divider sx={{ height: 2, m: 0.5 }} />
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "4px" }}>
                                <Typography>
                                    Initialize account:
                                </Typography>
                                <Checkbox checked={initializeAccount} onClick={() => {setInitializeAccount(!initializeAccount)}} />
                            </Box>
                            <Divider sx={{ height: 2, m: 0.5 }} />
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "4px" }}>
                                <Typography sx={{ color: !initializeAccount ? "text.secondary" : "" }}>
                                    First name:
                                </Typography>
                                <TextField
                                disabled={!initializeAccount}
                                size="small"
                                value={fnameVal}
                                onChange={(e) => {setFnameVal(e.target.value)}}
                                />
                            </Box>
                            <Divider sx={{ height: 2, m: 0.5 }} />
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "4px" }}>
                                <Typography sx={{ color: !initializeAccount ? "text.secondary" : "" }}>
                                    Last name:
                                </Typography>
                                <TextField
                                disabled={!initializeAccount}
                                size="small"
                                value={lnameVal}
                                onChange={(e) => {setLnameVal(e.target.value)}}
                                />
                            </Box>
                            <Divider sx={{ height: 2, m: 0.5 }} />
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "4px" }}>
                                <Typography sx={{ color: !initializeAccount ? "text.secondary" : "" }}>
                                    password:
                                </Typography>
                                <TextField
                                disabled={!initializeAccount}
                                size="small"
                                value={passwordVal}
                                onChange={(e) => {setPasswordVal(e.target.value)}}
                                />
                            </Box>
                            <Divider sx={{ height: 2, m: 0.5 }} />
                            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingLeft: "4px" }}>
                                <Typography>
                                    email:
                                </Typography>
                                <TextField
                                size="small"
                                value={newUser}
                                disabled={loading}
                                onChange={(e) => {setNewUser(e.target.value)}}
                                />
                            </Box>
                            <Box sx={{ display: "flex", paddingTop: "8px", paddingBottom: "8px" }}>
                                <Button
                                variant="contained"
                                endIcon={!initializeAccount ? <ForwardToInboxIcon /> : <LibraryAddIcon />}
                                fullWidth
                                onClick={handleAddUsers}
                                >
                                    {!initializeAccount ? "send invite email" : "create account"}
                                </Button>
                            </Box>
                        </Stack>
                    </Paper>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default AddUserPanel