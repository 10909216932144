import { Button, Dialog, DialogTitle, IconButton, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import ProgressAvatar from "../Components/ProgressAvatar"
import PaidIcon from "@mui/icons-material/Paid"
import { useEffect, useState } from "react"
import { IUser, useUser } from "../Context/User_context"
import { getUser } from "../Api/User_api"
import { useNavigate } from "react-router-dom"
import { requestPayout } from "../Api/User_api"
import HelpIcon from "@mui/icons-material/Help"
import Toc from "../Components/Toc"


const Home = () => {

    const { user, setNotification, refreshInfo } = useUser()
    const navigate = useNavigate()

    const [confirmPanel, setConfirmPanel] = useState(false)
    const [helpPanel, setHelpPanel] = useState(false)
    const [disclaimerPanel, setDisclaimerPanel] = useState(false)

    const handleCashout = async () => {
        const result = await requestPayout()
        if (result.type === "error") {
            setNotification({ type: result.type, msg: result.msg })
        } else {
            setNotification({ type: result.type, msg: result.msg })
            setConfirmPanel(false)
            refreshInfo()
        }
    }

    const [calculatedTierAmt, setCalculatedTierAmt] = useState(0)

    useEffect(() => {
        if (user && user.status === 2) {
            navigate("/manage")
        }
        setCalculatedTierAmt((user?.tiers && user.tiers.length !== 0)
        ?
            ((user.tiers[Math.floor(user.commission / user.tierAmt) + 1]
            ?
            user.tiers.slice(0, Math.floor(user.commission / user.tierAmt)).length > 0 ? user.tiers.slice(0, Math.floor(user.commission / user.tierAmt)).reduce((prev: number, curr: number) => prev + curr) : 0
            : 
                user.tiers.reduce((prev: number, curr: number) => prev + curr) + ((user.tiers[user.tiers.length - 1])) * (Math.floor(user.commission / user.tierAmt) - (user.tiers.length))))
        :
                user ? (user.payoutAmt * Math.floor(user.commission / user.tierAmt)) : 0
        )
    }, [user])

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", gap: "16px", paddingTop: 6 }}>
            {user && <>
                <Stack gap={3} sx={{ alignItems: "center" }}>
                    <ProgressAvatar userData={user} />
                    <Typography>
                        total Revenue: ${user.commission}
                    </Typography>
                    {calculatedTierAmt > 0 && 
                    <>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography sx={{ color: "success.light" }}>
                                Cashout available: ${calculatedTierAmt}
                            </Typography>
                            <IconButton onClick={() => {setHelpPanel(true)}}>
                                <HelpIcon />
                            </IconButton>
                        </Box>
                        <Button onClick={() => {setDisclaimerPanel(true)}} variant="contained" startIcon={<PaidIcon />} sx={{ width: "240px" }}>Request Cashout</Button>
                        <Button onClick={() => {setHelpPanel(true)}}>help</Button>
                    </>}
                </Stack>
                <Dialog
                onClose={() => {setConfirmPanel(false)}}
                open={confirmPanel}
                >
                    <DialogTitle sx={{ textAlign: "center" }}>
                        <Typography variant="h6" sx={{ paddingBottom: 1 }}>
                            You are about to cash out ${calculatedTierAmt}
                        </Typography>
                        <Typography>
                            Please Confirm you want to cash out.
                        </Typography>
                    </DialogTitle>
                    <Stack
                    direction="row"
                    gap={2}
                    sx={{ justifyContent: "center", paddingTop: "16px", paddingBottom: "56px" }}
                    >
                        <Button size="large" variant="contained" onClick={handleCashout}>Confirm</Button>
                        <Button onClick={() => {setConfirmPanel(false)}} size="large" variant="outlined">Cancel</Button>
                    </Stack>
                </Dialog>
                <Dialog
                onClose={() => {setHelpPanel(false)}}
                open={helpPanel}
                >
                    <Toc />
                </Dialog>
                <Dialog
                onClose={() => {setDisclaimerPanel(false)}}
                open={disclaimerPanel}
                >
                    <Toc />
                    <Stack sx={{ padding: 2, gap: 1 }}>
                        <Button size="large" variant="contained" onClick={() => {setDisclaimerPanel(false); setConfirmPanel(true)}}>I understand</Button>
                        <Button onClick={() => {setDisclaimerPanel(false)}} size="large" variant="outlined">Cancel</Button>
                    </Stack>
                </Dialog>
            </>}
        </Box>
    )
}

export default Home