import axios from "axios"
import { IUser } from "../Context/User_context"

export const auth = async () => {
    const result = await axios.get("/api/user/login")
    return result.data
}

export const login = async (email: string, password: string) => {
    const result = await axios.post("/api/user/login", {
        username: email,
        password: password,
    })
    return result.data
}

export const logout = async () => {
    const result = await axios.get("/api/user/logout")
    return result.data
}

export const register = async (fname: string, lname: string, password: string, token: string) => {
    const result = await axios.post("/api/user/register", {
        fname: fname,
        lname: lname,
        password: password,
        token: token,
    })
    return result.data
}

export const changePassword = async (passwordCurr: string, password: string) => {
    const result = await axios.post("/api/user/password", {
        passwordCurr: passwordCurr,
        password: password,
    })
    return result.data
}

export const getUsers = async () => {
    const result = await axios.get("/api/user/all")
    return result.data
}

export const getUser = async (id: number | undefined) => {
    if (id) {
        const result = await axios.get(`/api/user/${id}`)
        return result.data
    } else {
        const result = await axios.get("/api/user")
        return result.data
    }
}

export const getPendingUsers = async () => {
    const result = await axios.get("/api/user/pending")
    return result.data
}

export const getAdminUsers = async () => {
    const result = await axios.get("/api/user/admin")
    return result.data
}

export const addUsers = async (isAdmin: boolean, commissionVal: number, email: string) => {
    const result = await axios.post("/api/user/", {
        isAdmin: isAdmin,
        commissionVal: commissionVal,
        email: email,
    })
    return result.data
}

export const createUser = async (isAdmin: boolean, fname: string, lname: string, password: string, commissionVal: number, email: string) => {
    const result = await axios.post("/api/user/new", {
        fname: fname,
        lname: lname,
        password: password,
        isAdmin: isAdmin,
        commissionVal: commissionVal,
        email: email,
    })
    return result.data
}

export const deleteUsers = async (ids: any) => {
    const result = await axios.post(`/api/user/delete`, ids)
    return result.data
}

export const editUser = async (fname: string, lname: string, password: string | undefined, email: string) => {
    const result = await axios.post("/api/user/edit", {
        fname: fname,
        lname: lname,
        password: password,
        email: email,
    })
    return result.data
}

export const requestPayout = async () => {
    const result = await axios.get("/api/user/payout")
    return result.data
}

export const requestPasswordReset = async (email: string) => {
    const result = await axios.post("/api/user/password/request", {
        email: email
    })
    return result.data
}

export const resetPassword = async (password: string, token: string) => {
    const result = await axios.post("/api/user/password/reset", {
        password: password,
        token: token,
    })
    return result.data
}

export const commissionUser = async (id: number, amt: number, msg: string) => {
    const result = await axios.post("/api/user/comission", {
        id: id, 
        amt: amt, 
        msg: msg
    })
    return result.data
}

export const deleteCommission = async (id: number) => {
    const result = await axios.delete(`/api/user/comission/${id}`)
    return result.data
}