import { Alert, Button, Grow, IconButton, Paper, TextField, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { useUser } from "../Context/User_context"

const Account = () => {

    const { changePassword, setNotification, user } = useUser()
    const navigate = useNavigate()

    const [changePasswordButton, setChangePasswordButton] = useState(false)
    const [passwordCurr, setPasswordCurr] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [alert, setAlert] = useState("")

    const handleChangePassword = async () => {
        const result = await changePassword(passwordCurr, password)
        setNotification({ type: result.type, msg: result.msg })
        if (result.type !== "error") {
            setChangePasswordButton(false)
        }
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%", gap: "16px" }}>
            <Stack gap={3} sx={{ paddingTop: 3 }}>
                <Stack direction="row" gap={2} sx={{ alignItems: "center" }}>
                    <IconButton onClick={() => {navigate(-1)}}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h3" sx={{ fontSize: { xs: "2rem", md: "3rem" } }}>
                        My Account
                    </Typography>
                </Stack>
                <Stack gap={3} sx={{ paddingBottom: 3 }}>
                    <Box sx={{ display: "flex", alignItems: "flex-end", height: "48px" }}>
                        <Typography variant="h5">
                            Information
                        </Typography>
                    </Box>
                    <Paper sx={{ padding: "32px 16px", display: "flex", flexDirection: "column", gap: 4 }}>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography sx={{ width: { xs: "200px", sm: "auto" } }}>
                                Email Account
                            </Typography>
                            <Box sx={{ width: "230px" }}>
                                <Typography sx={{ paddingLeft: "14px", width: { xs: "200px", sm: "auto" } }}>
                                {user && user.email}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography sx={{ width: { xs: "200px", sm: "auto" } }}>
                                Name
                            </Typography>
                            <Box sx={{ width: "230px" }}>
                                <Typography sx={{ paddingLeft: "14px", width: { xs: "200px", sm: "auto" } }}>
                                    {user && user.fname} {user && user.lname}
                                </Typography>
                            </Box>
                        </Box>
                    </Paper>
                    <Box sx={{ display: "flex", alignItems: "flex-end", justifyContent: "space-between" }}>
                        <Typography variant="h5">
                            Password
                        </Typography>
                        <Grow in={Boolean(alert)}>
                            <Alert sx={{ marginBottom: "0px", }} severity="error">
                                {alert}
                            </Alert>
                        </Grow>
                    </Box>
                    <Paper sx={{ padding: "32px 16px", display: "flex", flexDirection: "column", gap: 4 }}>
                        {changePasswordButton ?
                        <>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography>
                                    Current Password
                                </Typography>
                                <TextField
                                sx={{ width: { xs: "150px", sm: "auto" } }}
                                type="password"
                                size="small"
                                value={passwordCurr}
                                onChange={(e) => {setPasswordCurr(e.target.value)}}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography>
                                    New Password
                                </Typography>
                                <TextField
                                sx={{ width: { xs: "150px", sm: "auto" } }}
                                type="password"
                                size="small"
                                value={password}
                                onChange={(e) => {setPassword(e.target.value)}}
                                onBlur={() => {((password.length !== 0 && passwordConfirm.length !== 0) && (password !== passwordConfirm)) ? setAlert("passwords do not match.") : setAlert("")}}
                                />
                            </Box>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <Typography
                                sx={{ opacity: password.length === 0 ? 0.5 : undefined }}
                                >
                                    Confirm New Password
                                </Typography>
                                <TextField
                                sx={{ width: { xs: "150px", sm: "auto" } }}
                                type="password"
                                size="small"
                                value={passwordConfirm}
                                onChange={(e) => {setPasswordConfirm(e.target.value)}}
                                onBlur={() => {((password.length !== 0 && passwordConfirm.length !== 0) && (password !== passwordConfirm)) ? setAlert("passwords do not match.") : setAlert("")}}
                                disabled={password.length === 0}
                                />
                            </Box>
                        </>
                        :
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <Typography>
                                •••••••••
                            </Typography>
                            <Button
                            sx={{ marginRight: 4 }}
                            onClick={() => {setChangePasswordButton(true)}}
                            >
                                change
                            </Button>
                        </Box>
                        }
                    </Paper>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box sx={{ flexGrow: 1 }} />
                        <Button disabled={Boolean(alert) || passwordConfirm.length === 0} onClick={handleChangePassword} variant="contained" size="large">update</Button>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}

export default Account