import { AppBar, Avatar, Box, Button, Divider, Drawer, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Popover, Stack, Toolbar, Tooltip, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { Container } from "@mui/system"
import { useUser } from "../Context/User_context"
import { useState } from "react"
import logo from "./LogoBanner.png"
import MenuIcon from "@mui/icons-material/Menu"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import PeopleIcon from "@mui/icons-material/People"
import SettingsIcon from "@mui/icons-material/Settings"
import NotesIcon from "@mui/icons-material/Notes"

const Header = () => {

    const navigate = useNavigate()

    const { user, logout } = useUser()

    const [menuPopover, setMenuPopover] = useState(false)
    const [drawer, setDrawer] = useState(false)
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)

    const menu = [
        {
            name: "Manage Users",
            link: "/manage",
            icon: <PeopleIcon />,
        },
        {
            name: "Settings",
            link: "/settings",
            icon: <SettingsIcon />,
        },
        {
            name: "Audit",
            link: "/audit",
            icon: <NotesIcon />,
        },
    ]

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {user &&
                        <Box sx={{ display: { xs: "flex", md: "none" }, flexGrow: 1 }}>
                            <IconButton onClick={() => {setDrawer(true)}}>
                                <MenuIcon />
                            </IconButton>
                        </Box>
                    }
                    <img
                    src={logo}
                    style={{ height: "56px", marginRight: 2, cursor: "pointer" }}
                    onClick={() => {navigate("/")}}
                    />
                    {user &&
                    <>
                        <Box sx={{ display: { xs: "none", md: "flex" }, flexGrow: 1 }}>
                            {user.status == 2 && menu.map((item) => (
                                <Button
                                key={item.name}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                                onClick={() => {navigate(item.link)}}
                                >
                                    {item.name}
                                </Button>
                            ))}
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
                            <Tooltip title="Open settings">
                                <IconButton
                                onClick={(e) => {setAnchorEl(e.currentTarget)}}
                                >
                                    <Avatar>
                                        {/* {user.fname.substring(1)}{user.lname.substring(1)} */}
                                    </Avatar>
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }} />
                        <Popover
                        open={Boolean(anchorEl)}
                        onClose={() => {setAnchorEl(null)}}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        >
                            <MenuItem onClick={() => {setAnchorEl(null); navigate("/account"); setDrawer(false);}}>
                                Account
                            </MenuItem>
                            <MenuItem onClick={async () => {await logout(); setAnchorEl(null); navigate("/"); setDrawer(false);}}>
                                Logout
                            </MenuItem>
                        </Popover>
                    </>
                    }
                </Toolbar>
            </Container>
            <Drawer
            anchor="left"
            open={drawer}
            onClose={() => {setDrawer(false)}}
            >
                <Stack sx={{ paddingTop: 1, paddingBottom: 2, alignItems: "center", height: "100%" }}>
                    <ListItemButton sx={{ display: "flex", flexGrow: 0, flexDirection: "column", width: "100%", alignItems: "flex-start", padding: 1 }}
                    onClick={(e) => {setAnchorEl(e.currentTarget)}}
                    >
                        <IconButton>
                            <Avatar>
                                {/* {user && user.fname.substring(1)}{user && user.lname.substring(1)} */}
                            </Avatar>
                        </IconButton>
                        <Typography sx={{ paddingLeft: 1, }}>
                            {user && `${user.fname} ${user.lname}`}
                        </Typography>
                        <Box sx={{ display: "flex" }}>
                            <Typography variant="subtitle2" sx={{ paddingLeft: 1, color: "text.secondary" }}>
                                {user && `${user.email}`}
                            </Typography>
                            <ExpandMoreIcon />
                        </Box>
                    </ListItemButton>
                    <Box sx={{ width: "100%" }}>
                        <Divider variant="middle" />
                    </Box>
                    {menu.map(item => (
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => {setDrawer(false); navigate(item.link)}}>
                                <ListItemIcon>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                    <Box sx={{ flexGrow: 1 }} />
                </Stack>
            </Drawer>
        </AppBar>
    )
}

export default Header