import { Navigate, Outlet } from "react-router-dom"
import { useUser } from "../Context/User_context"

const ProtectedRoute = () => {
    const { user } = useUser()

    if (typeof(user) === "undefined") {
        return (<></>)
    } else if (user) {
        return (<Outlet />)
    } else {
        return (<Navigate to="/login" replace />)
    }
}

export default ProtectedRoute